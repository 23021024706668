import React from 'react'
import styles from './Commerce.module.scss'

const commerce_items = [
    {
        description: `
            <h3 class="subhead">販売業者：</h3>
            <p>株式会社grid.io</p>
            <h3 class="subhead">代表責任者：</h3>
            <p>鍵谷正</p>
            <h3 class="subhead">所在地：</h3>
            <p>〒180-0004</br>
            東京都武蔵野市吉祥寺本町1-24-7　吉祥寺光ビル6F</p>
            <h3 class="subhead">電話番号：</h3>
            <p>0422-27-6843</p>
            <h3 class="subhead">電話対応時間：</h3>
            <p>火～金　12:00～22:00／土日　10:30～22:00（月曜 定休日）</p>
            <h3 class="subhead">メールアドレス：</h3>
            <p><a href="mailto:info@gridio.co">info@gridio.co</a></p>
            <h3 class="subhead">販売価格：</h3>
            <p>各商品ごとに掲載</p>
            <h3 class="subhead">各種イベント：</h3>
            <ul>
                <li>イベントチケット価格、イベント参加費用はイベント毎に異なります。</li>
                <li>参加申込みフォーム、イベントWEBに金額が表示されます。</li>
            </ul>
            <h3 class="subhead">支払方法：</h3>
            <p>各種前払い（現金、クレジットカード決済、電子マネー、銀行振込）</p>
            <h3 class="subhead">支払時期：</h3>
            <p>イベント毎に異なります。</br>
            参加申込みフォーム、イベントWEBに金額が表示されます。</p>
            <h3 class="subhead">商品の引渡時期：返金・交換：</h3>
            <p>お客様の都合によるお申し込み後の商品変更・ご注文の取り消しはできません。</p>
            <h3 class="subhead">公演中止等に伴う払い戻し：</h3>
            <p>
                不可抗力による公演中止の場合等は、所定の期間内に限り、払い戻しを行います。</br>
                但し、この場合チケットを破損・紛失したり、甚だしく汚損し判別しがたい場合、一切払い戻しは致しません。</br>
                なお、返金までに3～4週間ほどお時間をいただく場合がございます。
            </p>
        `
    },
]

const CommerceItem = ({item}) => {
    const description = item.description;

    return (
        <div dangerouslySetInnerHTML={{__html: description}} />
    );
};

export default () => (
    <section className={`${styles.commerce} l-wrapper l-container`}>
        <div>
            <h2 className={`head`}>特定商取引法に基づく表記</h2>
            {commerce_items.map(item => <CommerceItem item={item} />)}
        </div>
    </section>
)
